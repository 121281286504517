// import { User } from '@models/user.model';

import { ToggleOptions } from '@core/_base/layout/directives/toggle.directive';

// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Store, select } from '@ngrx/store';
import { CookiesService } from '~shared/services/coookies.service';
import { AuthService } from '@core/security/services/auth.service';
import { AppState } from '@core/store';
import { currentUser } from '@core/store/selectors/auth.selectors';
import { Logout } from '@core/store/actions/auth.actions';
// NGRX

// State

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$!: Observable<any>;

	toggleOptions: ToggleOptions = {
		target: 'body',
		targetState: 'kt-header__topbar--mobile-on',
		togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active',
	};

	@Input() avatar = false;
	@Input() greeting = true;
	@Input() badge!: boolean;
	@Input() icon!: boolean;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private readonly store: Store<AppState>, private readonly auth: AuthService, private readonly _cookiesService: CookiesService) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.user$ = this.store.pipe(select(currentUser));
	}

	/**
	 * Log out
	 */
	logout() {
		const token = this._cookiesService.getItem(environment.authTokenKey);
		this.auth.logout(token)?.subscribe();
		this.store.dispatch(new Logout());
	}
}
